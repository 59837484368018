'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});
exports['default'] = void 0;
var _KryptonGlue = _interopRequireDefault(require('./KryptonGlue'));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = _KryptonGlue['default'];
exports['default'] = _default;